<template>
  <div>
    <md-dialog :md-active.sync="isFavModalOpen" class="md-dialog-tab">
      <md-dialog-title class="modal-header"> {{isEditableLine ? 'Edit' : 'New'}} Favline
        <button
          type="button"
          class="btn-close"
          @click="close"
          aria-label="Close modal"
        >
          <span class="material-icons">close</span>
        </button>
      </md-dialog-title>
      <form novalidate class="fav-line-modal"
            @submit.prevent="validateBeforeSave" enctype="multipart/form-data">
        <md-progress-bar class="md-accent" md-mode="indeterminate" v-if="newLineLoading"/>
        <md-content class="modal-body"
                    id="modalDescription">
          <div class="md-layout">
            <div class="md-layout-item">
                <input class="modal-input form-control input-with-border" v-model="form.url"
                       placeholder="URL (example.com)" @click="clickOutName">
              <md-field :class="[getValidationClass('name'),'md-field-site']">
                  <vue-autosuggest
                    v-model="form.name"
                    :suggestions="filteredData1"
                    @click="clickHandler"
                    @input="onInputChange"
                    @focus="focusMe"
                    @selected="onSelected"
                    :get-suggestion-value="getSuggestionValue"
                    :input-props="{id:'autosuggest__input', placeholder:'Name', class:'modal-input form-control input-with-border'}">
                    <div slot-scope="{suggestion}" style="display: flex; align-items: center;">
                      <div class="favline-suggestion">
                        <p class="favline-name"> {{suggestion.item.name}} </p>
                        <p class="favline-divider"> &nbsp;/&nbsp;</p>
                        <p class="favline-id"> {{ suggestion.item.id }} </p>
                      </div>
                    </div>
                  </vue-autosuggest>
                <div class="md-error" v-if="!$v.form.name.required">
                  {{ $t('missingValue') }}
                </div>
              </md-field>
              <label> Color Picker:</label>
              <input class="modal-input form-control" name="bgcolor"
                     id="bgcolor"
                     type="color"
                     v-model="form.bgcolor"
                     @click="clickOutName"
                     placeholder="Color" style="width:30%; height:50px; padding-left: 2px;"
              />
            </div>
            <div class="md-layout-item">
              <input class="modal-input form-control input-with-border" name="space"
                     id="space"
                     type="number"
                     v-model="form.space"
                     @click="clickOutName"
                     placeholder="Space"
              />
              <div class="quality">
                <input class="modal-input form-control input-with-border" name="quality" max="2"
                     id="quality"
                     type="number"
                     v-model="form.quality"
                     @click="clickOutName"
                     placeholder="Quality"
              />
              </div>
              <div v-if="lineId" class="line-value">
                Line value:
                <p> {{ lineId }}</p>
              </div>
              <div v-if="isEditableLine" class="line-value">
                <md-checkbox v-model="form.is_enhanced">Enhanced</md-checkbox>
              </div>
            </div>
          </div>
        </md-content>

        <md-dialog-actions class="modal-footer">
          <div class="md-layout md-buttons-modal md-layout-flex-1">
            <md-button class="md-raised md-primary site-save-button" type="submit" style="margin-bottom: 30px;">
              {{ $t('userTopTabs.save') }}
            </md-button>
          </div>
        </md-dialog-actions>
      </form>

    </md-dialog>
  </div>
</template>


<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import formHelpersMixin from '@/mixins/formHelpersMixin';
import loaderMixin from '@/mixins/loaderMixin';
import { VueAutosuggest } from 'vue-autosuggest';
import { FETCH_FAV_LINES } from '@/store/modules/admin/resources/favLine/favLinesActions.type';
import {
  ADMIN_CREATE_FAV_LINE,
  ADMIN_EDIT_FAV_LINE,
} from '@/store/modules/admin/single/favPage/favPageActions.type';

export default {
  name: 'FavLineModal',
  components: {
    VueAutosuggest,
  },
  props: {
    isFavModalOpen: Boolean,
    isEditableLine: {
      type: [Object, NaN],
    },
  },
  mixins: [
    validationMixin,
    formHelpersMixin,
    loaderMixin,
  ],
  data: () => ({
    form: {
      id: null,
      url: null,
      name: null,
      bgcolor: null,
      space: null,
      quality: null,
      is_enhanced: false,
    },
    requiredFields: false,
    lineId: null,
    query: '',
    selected: '',
    newLineLoading: false,
    filteredData1: [],
  }),
  computed: {
    favLinesData() {
      return this.$store.getters.favLinesData;
    },
    favPageData() {
      return this.$store.getters.adminFavPageData;
    },
  },
  validations: {
    form: {
      name: {
        required,
      },
    },
  },
  mounted() {
    this.loadFavLines();
    this.getModal();
  },
  methods: {
    filteredOptions() {
      const filteredData = !this.isEditableLine ? [] : this.favLinesData.filter(option => option.name.toLowerCase().indexOf(this.query.toLowerCase()) > -1);
      if (document.getElementById('autosuggest-autosuggest__results')) {
        const height = filteredData.length * 50;
        const divHeight = height >= 200
          ? 200
          : height;
        const value = `height: ${divHeight}px`;
        document.getElementById('autosuggest-autosuggest__results').setAttribute('style', value);
      }

      this.filteredData1 = [
        {
          data: filteredData,
        },
      ];
    },
    loadFavLines() {
      this.$store.dispatch(FETCH_FAV_LINES)
        .then((response) => {
          this.$log.info('FETCH_FAV_LINES', response);
        })
        .catch((error) => {
          this.$log.error('FETCH_FAV_LINES', error);
        });
    },
    focusMe(e) {
      console.log(e);
    },
    clickHandler(e) {
      console.log(e);
      // document.getElementById('autosuggest-autosuggest__results').setAttribute('style', 'height:200px');
    },
    clickOutName() {
      document.getElementById('autosuggest-autosuggest__results').setAttribute('style', 'height:0px');
    },
    onSelected(item) {
      this.selected = item.item;
      this.lineId = item.item.id;
      this.form.name = item.item.name;
      document.getElementById('autosuggest-autosuggest__results').setAttribute('style', 'height:0px');
    },
    onInputChange(text) {
      this.form.name = text;
      if (!this.isEditableLine) {
        this.lineId = null;
      }
      this.query = text;
      this.filteredOptions();
    },
    getSuggestionValue(suggestion) {
      return suggestion.item.name;
    },
    getModal() {
      this.isCellModalVisibleOn = !!this.isCellModalVisible;
    },
    validateBeforeSave() {
      this.$v.$touch();
      this.errors = {};
      if (!this.$v.$invalid) {
        this.save();
      } else {
        this.requiredFields = true;
      }
    },
    save() {
      this.newLineLoading = true;
      const payload = {
        lineId: this.lineId ?? null,
        pageId: this.favPageData.id.slice(0, -1),
        url: this.form.url,
        name: this.form.name,
        bgcolor: this.form.bgcolor,
        space: this.form.space,
        quality: this.form.quality,
        is_enhanced: this.form.is_enhanced,
      };
      this.$store.dispatch(this.isEditableLine ? ADMIN_EDIT_FAV_LINE : ADMIN_CREATE_FAV_LINE, payload)
        .then((response) => {
          this.$log.info(this.isEditableLine ? ADMIN_EDIT_FAV_LINE : ADMIN_CREATE_FAV_LINE, payload);
          this.$toasted.show(response.message, {
            type: 'success',
            position: 'top-right',
            duration: 5000,
            icon: 'check',
          });
          this.clearForm();
          this.$emit('reloadData');
          this.newLineLoading = false;
          this.timeout = setTimeout(() => {
            window.close();
          }, 700);
        })
        .catch((error) => {
          this.$log.error(this.isEditableLine ? ADMIN_EDIT_FAV_LINE : ADMIN_CREATE_FAV_LINE, error);
          this.$toasted.show(error.message, {
            type: 'error',
            position: 'top-right',
            duration: 5000,
            icon: 'alert',
          });
          if (error.status === 'validation_error' && error.errors) {
            this.errors = error.errors;
          }
          this.newLineLoading = false;
        });
    },
    close() {
      this.$v.$reset();
      this.$emit('close');
    },
    clearForm() {
      this.close();
      this.$v.$reset();
    },
  },
  watch: {
    isEditableLine: {
      handler(obj) {
        if (obj) {
          this.lineId = obj.id;
          this.form.bgcolor = obj.bgcolor;
          this.form.name = obj.name;
          this.form.space = obj.space;
          this.form.url = obj.url;
          this.form.quality = obj.quality;
          this.form.is_enhanced = obj.is_enhanced;
        } else {
          this.lineId = null;
          this.form.bgcolor = null;
          this.form.name = null;
          this.form.space = null;
          this.form.url = null;
          this.form.quality = null;
          this.form.is_enhanced = false;
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/layout/favCell-modal.scss';
.quality {
  margin-top: 21px;
}
.line-value {
  margin-top: 10px;
}
.autosuggest__results {
  height: auto;
}
.autosuggest__results ul {
  color: rgba(30, 39, 46,1.0);
  list-style: none;
  margin: 0;
  padding: 0.5rem 0 .5rem 0;
}
.autosuggest__results li {
  margin: 0 0 0 0;
  border-radius: 5px;
  padding: 0.75rem 0 0.75rem 0.75rem;
  display: flex;
  align-items: center;
}
li:hover {
  cursor: pointer;
}
.autosuggest-container {
  display: flex;
  justify-content: center;
}
#autosuggest { width: 100%; display: block;}
.autosuggest__results-item--highlighted {
  background-color: rgba(51, 217, 178,0.2);
}
#autosuggest-autosuggest__results {
  max-height: 200px;
  max-width: 245px;
  overflow-y: auto;
  overflow-x: auto;
}
.favline-suggestion {
  display: flex;
  border-radius: 5px;
  height: 1.5rem;
  align-items: center;
}
.favline-name, .favline-divider {
  color: #a5a5a5;
  font-weight: 600;
}
.favline-id {
  color: #C44B57;
  font-weight: 700;
}
.favline-divider {
  font-weight: 900;
}
</style>
